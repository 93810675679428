import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    usersData: {
      name: '',
      subject: '',
      email: ''
    }
  },
  mutations: {},
  actions: {},
})

export default store