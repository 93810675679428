import Vue from 'vue'
import App from './App.vue'
import store from './store'
import AudioRecorder from 'vue-audio-recorder'
import router from './router'
import axios from "axios";

import styles from './assets/scss/main.scss'
import i18n from './plugins/i18n'

Vue.config.productionTip = false

Vue.use(AudioRecorder)

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  styles,
  i18n,
  render: h => h(App)
}).$mount('#app')
