import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/layout'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/home')
      },
      {
        path: 'data',
        name: 'data',
        component: () => import('../views/data')
      },
      {
        path: 'player',
        name: 'player',
        component: () => import('../views/player')
      },
      {
        path: '*',
        redirect: { name: 'home' },
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
